<template>
  <div>
    <el-upload v-bind="$attrs" v-on="$listeners" ref="myupload" :on-error="onError" :before-upload="beforeUpload" :on-success="onSuccess">
      <template v-for="(_, slotName) in $slots" v-slot:[slotName] >
        <slot :name="slotName"></slot>
      </template>
    </el-upload>
  </div>
</template>

<script>
export default {
  name:'MyUpload',
  data () {
    return {
      canUpload:true
    }
  },
  mounted () {
    this.$nextTick(()=>{
      const myupload = this.$refs.myupload;
      const methodsToBind = Object.getOwnPropertyNames(
        Object.getPrototypeOf(myupload)
      )

      // 遍历子组件中用到的所有方法实例到myupload上面去
      methodsToBind.forEach((method) => {
        if (typeof myupload[method] === "function") {
          this[method] = myupload[method].bind(myupload);
        }
      })
      // 如果需要添加自定义方法，可以在这里定义
      this.customMethod = () => {
        
      };
    })
  },
  methods: {
    onError(...argument){
      this.$attrs['on-error'](...argument)
    },
    onSuccess(...argument){
      this.$attrs['on-success'](...argument)
    },
    beforeUpload(file){
      return this.canUpload&&this.$attrs['before-upload']!=false
    },

  }
}
</script>

<style lang='less' scoped>

</style>